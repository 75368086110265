import { countriesList } from '~/assets/js/countriesList'

const countries = (locale) => {
  return countriesList
    .map((country) => ({
      value: country.iso,
      label: country.country[locale],
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
}

const nationalities = (locale) => {
  return countriesList
    .filter((country) => country.nationality[locale])
    .map((country) => ({
      value: country.iso,
      label: country.nationality[locale],
    }))
    .filter((country) => country.label)
    .sort((a, b) => a.label.localeCompare(b.label))
}

const civilityOptions = [
  {
    value: 'mrs',
    labelTranslateKey: 'field.civilityFemale',
  },
  {
    value: 'mr',
    labelTranslateKey: 'field.civilityMale',
  },
  {
    value: 'unspecified',
    labelTranslateKey: 'field.civilityUnspecified',
  },
]

const identityOptions = (t) => {
  return [
    {
      value: 'individual',
      label: t('field.individual'),
    },
    {
      value: 'company',
      label: t('field.company'),
    },
  ]
}
const ownerTypeOptions = (translate) => {
  return [
    { value: 'company', text: translate.company },
    { value: 'individual', text: translate.individual },
  ]
}

export {
  civilityOptions,
  countries,
  identityOptions,
  nationalities,
  ownerTypeOptions,
}
